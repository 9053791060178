import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, mergeMap, map, of } from 'rxjs';
import { FxProvider } from '../models/fx-provider.model';
import { FxProviderFileMapping, CurrencyLogicalFieldsMap, CurrencyLogicalFields, LogicalFieldMetadata } from '../models/logical-field.model';
import { GridSearchResponse } from '../../../../grid/models/grid-search-response.model';
import { NotFoundOfficeFxProviderError, NotSupportedCurrencyError, NotSupportedFxProviderError } from '../models/fx-logical-fields-error.model';
import {catchError} from "rxjs/operators";
import {NotificationService} from "@fgpp-ui/components";
import {TranslateService} from "@ngx-translate/core";


@Injectable()
export class FxLogicalFieldsService {

  private readonly fxProviderFileMappingUrl = 'assets/fx/fx-provider-file-mapping.json';
  private readonly fxCurrencyFieldsBaseUrl = 'assets/fx/currency-fields';
  private readonly fxProvidersUrl = window.location.origin + '/ui/profiles/660';

  private fxProviderFileMapping: FxProviderFileMapping;
  private fxProviderPerOffice = new Map<string, string>();
  private currencyLogicalFieldsMapPerFxProvider = new Map<string, CurrencyLogicalFieldsMap>();

  constructor(private http: HttpClient, private notificationService: NotificationService, private translateService: TranslateService) {
  }

  getLogicalFields(office: string, currency: string): Observable<CurrencyLogicalFields> {
    return this.getLogicalFieldArray(office, currency).pipe(map((response: Array<LogicalFieldMetadata>) => {
      return {
        new: response.filter(item => item.isNew),
        old: response.filter(item => !item.isNew)
      };
    }));
  }

  getLogicalFieldArray(office: string, currency: string): Observable<Array<LogicalFieldMetadata>> {
    return this.getFxProviderName(office).pipe(mergeMap((fxProviderName: string) => {
      return this.getLogicalFieldsMap(fxProviderName).pipe(map((response: CurrencyLogicalFieldsMap) => {
        const logicalFields = response[currency];
        if (!logicalFields) {
          throw new NotSupportedCurrencyError(currency, this.fxProviderFileMapping[fxProviderName]);
        }
        return logicalFields;
      }));
    }));
  }

  private getFxProviderName(office: string): Observable<string> {
    if (this.fxProviderPerOffice.has(office)) {
      return of(this.fxProviderPerOffice.get(office));
    }
    return this.http.get(this.fxProvidersUrl, {params: new HttpParams().set(FxProvider.OFFICE, office)})
      .pipe(catchError(err => {
        if (err?.status === 403) {
          this.notificationService.error(this.translateService.instant('msessages.fx-provider.error_403'));
        }
        throw new NotFoundOfficeFxProviderError(office);
      }))
      .pipe(map((response: GridSearchResponse) => {
        if (!response?.rows?.length) {
          throw new NotFoundOfficeFxProviderError(office);
        }
        const fxProviderName = response.rows[0][FxProvider.FX_PROVIDER_NAME];
        this.fxProviderPerOffice.set(office, fxProviderName);
        return fxProviderName;
      }));
  }

  private getLogicalFieldsMap(fxProviderName: string): Observable<CurrencyLogicalFieldsMap> {
    if (this.currencyLogicalFieldsMapPerFxProvider.has(fxProviderName)) {
      return of(this.currencyLogicalFieldsMapPerFxProvider.get(fxProviderName));
    }
    return this.getCurrencyLogicalFieldsUrl(fxProviderName).pipe(mergeMap((url: string) => {
      return this.http.get<CurrencyLogicalFieldsMap>(url).pipe(map((response: CurrencyLogicalFieldsMap) => {
        this.currencyLogicalFieldsMapPerFxProvider.set(fxProviderName, response);
        return response;
      }));
    }));
  }

  private getCurrencyLogicalFieldsUrl(fxProviderName: string): Observable<string> {
    return this.getFxProviderFileMapping().pipe(map((mapping: FxProviderFileMapping) => {
      if (!mapping[fxProviderName]) {
        const originalFxProviderName = fxProviderName;
        fxProviderName = Object.keys(mapping).find((providerName: string) => fxProviderName.toUpperCase().includes(providerName.toUpperCase()));
        if (!fxProviderName) {
          throw new NotSupportedFxProviderError(originalFxProviderName);
        }
      }
      return `${this.fxCurrencyFieldsBaseUrl}/${mapping[fxProviderName]}`;
    }));
  }

  private getFxProviderFileMapping(): Observable<FxProviderFileMapping> {
    if (this.fxProviderFileMapping) {
      return of(this.fxProviderFileMapping);
    }
    return this.http.get<FxProviderFileMapping>(this.fxProviderFileMappingUrl).pipe(map((mapping: FxProviderFileMapping) => {
      this.fxProviderFileMapping = mapping;
      return this.fxProviderFileMapping;
    }));
  }
}
